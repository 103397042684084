.templateContainer {
    display: flex;
    flex-direction: column; /* Stack the elements vertically */
    align-items: center; /* Center horizontally */
    margin-top: 20px;
  }
  
  .correlationFont {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto";
    font-size: 19px;
    color: #303030;
    margin-bottom: 3vw; /* Add some space between text and image */
  }
  
  .imageContainer {
    display: flex;
    flex-direction: column; /* Stack the images vertically */
    align-items: center; /* Center horizontally */
  }
  
  .mainHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto";
    font-size: 27px;
    margin:2vw;
    color: #303030;
  }
  
  .explanationPara {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    text-align: center; /* Center-align the text */
    flex-wrap: wrap;
    margin: 2.5vw;
    font-family: "Roboto";
    font-size: 20px;
    color: #505050;
  }

  .AAstockTicker {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-family: "Roboto";
    color: #3A6AB6;
    margin:20px
  }

  .CstockTicker {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-family: "Roboto";
    color: #8F2727;
    margin:20px

  }
  @media screen and (max-width: 768px) {
    .mainHeader {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-family: "Roboto";
      font-size: 2rem;
      color: #303030;
    }
    .explanationPara {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;
      text-align: center; /* Center-align the text */
      flex-wrap: wrap;
      margin: 2.5vw;
      font-family: "Roboto";
      font-size: 20px;
      color: #505050;
    }
    .imageContainer {
      display: flex;
      flex-direction: column; /* Stack the images vertically */
      align-items: center; /* Center horizontally */
    }
  }