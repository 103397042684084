/* CenteredMainVideo */
.CenteredMainVideo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%; /* Adjust this as needed to center vertically */
  position: relative; /* Added position relative to the container */
}

.HeaderFont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute; /* Position the header absolutely */
  bottom: 0;
  right: 0;
  width: 100%; /* Make it take the full width */
  height: 100%; /* Make it take the full height */
  font-size: 5vw;
  color: white;
  font-family: "Roboto"; /* Assuming 'Roboto' is a web-safe font, otherwise, include the correct font URL */
}

.MissionBox {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.AboutBox {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.MissionWords {
  display: flex;
  flex-direction: column; /* Stack the text vertically */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  font-size: 24px;
  color: gray;
  flex-wrap: wrap;
  padding-top: 5vh;
  text-align: center; /* Center the text within the container */
  padding-bottom: 8vh;
}

.AboutWords {
  display: flex;
  flex-direction: column; /* Stack the text vertically */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  font-size: 24px;
  color: gray;
  flex-wrap: wrap;
  padding-top: 3vh;
  text-align: center; /* Center the text within the container */
  padding-bottom: 5vh;
}
.Video{
  width:100%;
  height:auto
}

@media screen and (max-width: 768px) {
  .logo {
    display: none;
  }
  .Video{
    width:150vw;
    height:auto
  }

}

.logo {
  margin-left: 25px;
  margin-top: 26px;
  z-index: 3;
  position: absolute;
  width: 3.5rem;
}
