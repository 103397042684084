.ApplyHeader {
    flex-direction: column; /* Stacks items vertically */
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto";
    font-size: 25px;
    padding-top:30px;
    color:#505050
}

.form {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:20px;
}

